/* eslint-disable no-unused-vars */
export enum OwnerPropertyCardType {
  ONBOARDING = "Onboarding",
  PUBLISHED = "Published",
  RESERVATION = "Reservation",
  IN_MANAGEMENT = "InManagement",
  CAPTURED_WITH_ACTIVE_LEASING = "CapturedWithActiveLeasing",
  PUBLISHED_WITH_ACTIVE_LEASING = "PublishedWithActiveLeasing",
  PENDING_SIGNATURE = "PendingSignature",
  RESIGNATION = "Resignation",
  NOT_RENTED = "NotRented",
  CANCELLED = "Cancelled",
  PENDING_RECOVER = "PendingRecover",
  PENDING_RECOVER_FUTURE_DATE = "PendingRecoverFutureDate",
}

export enum PropertyActiveProcessCardType {
  RESIGNATION = "Resignation",
  CAPTURED = "Captured",
  CAPTURED_NOT_CLICKABLE = "CapturedNotClickable",
  PUBLISHED = "Published",
  PENDING_SIGNATURE = "PendingSignature",
  IN_MANAGEMENT = "InManagement",
  NOT_RENTED = "NotRented",
  CANCELLED = "Cancelled",
  PENDING_RECOVER = "PendingRecover",
  PENDING_RECOVER_FUTURE_DATE = "PendingRecoverFutureDate",
}

export enum PropertyCardSubtitleType {
  ALERT = "alert",
  INFO = "info",
  DEFAULT = "default",
}

export enum PropertyCardBadgeType {
  RENT = "rent",
  SUCCESS = "success",
  DEFAULT = "default",
}
